import axios, { AxiosError, Method, AxiosRequestHeaders } from "axios";
import React from "react";
import { SODASOFT_API_ENDPOINT } from "../constantes/apiUrl";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { alertAtom, AlertSeverity } from "../componentes/Snackbar/atom";
import { tokenAtom } from "./../autenticacion/atoms/AuthState";

interface UseAxiosArgumentos {
  urlInicial: string;
  method?: Method;
  queryParams?: Record<string, unknown>;
  body?: Record<string, unknown>;
  callback?: (
    data: Record<string, unknown> | null
  ) => Promise<unknown> | unknown;
  handleError?: (error: string, statusCode: number) => void;
  baseURL?: string;
  disableNotifications?: boolean;
}

interface UseAxiosResultData {
  loading: boolean;
  actualizarUrl: (newUrl: string) => void;
  data: Record<string, unknown> | null;
  error?: string;
  status?: number;
  llamadaRealizada: boolean;
}

interface FetchDataInterface {
  (argumentosOpcionales?: {
    body?: Record<string, unknown>;
    queryParams?: Record<string, unknown>;
    url?: string;
    extraCallback?: () => void | null;
  }): Promise<Record<string, unknown>>;
}

interface UseAxiosInterface {
  (argumentos: UseAxiosArgumentos): [FetchDataInterface, UseAxiosResultData];
}

export const useAxios: UseAxiosInterface = ({
  queryParams,
  method = "get",
  urlInicial,
  callback,
  handleError,
  body,
  baseURL,
  disableNotifications = false,
}) => {
  const token = useRecoilValue(tokenAtom);

  const headers: AxiosRequestHeaders = {
    "Content-type": "application/json; charset=UTF-8",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const axiosInstance = axios.create({
    baseURL: baseURL || SODASOFT_API_ENDPOINT,
    headers,
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [urlLocal, actualizarUrl] = React.useState<string>(urlInicial);
  const [data, setData] = React.useState(null);
  const [status, setStatus] = React.useState<number | undefined>(undefined);
  const [error, setError] = React.useState<string | undefined>();
  const [localParams, actualizarQueryParams] = React.useState(queryParams);
  const [llamadaRealizada, setLlamadaRealizada] =
    React.useState<boolean>(false);
  const setAlert = useSetRecoilState(alertAtom);

  const fetchData: FetchDataInterface = async (parametrosOpcionales) => {
    const {
      body: optionalBody,
      queryParams: optionalParams,
      url: optionalUrl,
      extraCallback,
    } = parametrosOpcionales || {};
    try {
      setLoading(true);
      const result = await axiosInstance({
        method,
        url: optionalUrl || urlLocal,
        params: optionalParams || localParams,
        data: optionalBody || body,
        transformResponse: function (data) {
          setData(data);
          callback && callback(data);
          return data;
        },
      });
      setLlamadaRealizada(true);
      !disableNotifications &&
        setAlert((prev) => [
          ...prev,
          { message: "Operación existosa", severity: AlertSeverity.SUCCESS },
        ]);
      extraCallback && extraCallback();
      return result.data;
    } catch (e) {
      setLlamadaRealizada(true);
      const error: Error | AxiosError = e as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        if (error.response) {
          const { data, status, statusText } = error.response;
          setData(data);
          setStatus(status);
          !disableNotifications &&
            setAlert((prev) => [
              ...prev,
              { message: data || statusText, severity: AlertSeverity.ERROR },
            ]);
          handleError && handleError(statusText, status);
        } else if (error.request) {
          setError("No se recibio respuesta alguna del backend.");
          !disableNotifications &&
            setAlert((prev) => [
              ...prev,
              {
                message: "No se recibio respuesta alguna del backend.",
                severity: AlertSeverity.ERROR,
              },
            ]);
          handleError &&
            handleError("No se recibio respuesta alguna del backend.", 0);
        }
      } else {
        setError(error.message);
        !disableNotifications &&
          setAlert((prev) => [
            ...prev,
            { message: error.message, severity: AlertSeverity.ERROR },
          ]);
        handleError && handleError(error.message, -1);
      }
    } finally {
      setLoading(false);
    }
  };

  return [
    fetchData,
    {
      loading,
      actualizarUrl,
      data,
      error,
      status,
      llamadaRealizada,
      actualizarQueryParams,
    },
  ];
};
