import React, { ReactElement } from "react";
import NoSsr from "@mui/material/NoSsr";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import Theme from "./Tema";

const CustomThemeProvider = function (props: any): ReactElement {
  return (
    <NoSsr>
      <MuiThemeProvider theme={Theme}>{props.children}</MuiThemeProvider>
    </NoSsr>
  );
};

export default CustomThemeProvider;
