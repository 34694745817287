import { SnackbarProvider } from "notistack";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Snackbar from "./componentes/Snackbar/Snackbar";
import Router from "./Router";
import ThemeProvider from "./tema/ProveedorTema";

function App() {
  return (
    <div className="App">
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider>
          <BrowserRouter>
            <Snackbar />
            <Router />
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </div>
  );
}

export default App;
