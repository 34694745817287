import React from "react";
import { Outlet } from "react-router-dom";
import LoadingScreen from "../../vistas/Loading/Loading";
import { withCustomAuthenticationRequired } from "../atoms/AuthState";

const AuthGuard: React.FC = () => {
  return <Outlet />;
};

export default withCustomAuthenticationRequired(AuthGuard, {
  onRedirecting: () => <LoadingScreen />,
});
