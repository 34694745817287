import React, { Fragment, useEffect } from "react";
import type { FC } from "react";
import NProgress from "nprogress";
import { Box, LinearProgress } from "@mui/material";
import { styled } from "@mui/system";
import { userAtom } from "../../autenticacion/atoms/AuthState";
import { useRecoilValue } from "recoil";
import AppNavigation from "../../componentes/AppNavigation/AppNavigation";

const Root = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  minHeight: "100%",
  padding: theme.spacing(3),
}));

const LoadingScreen: FC = () => {
  const user = useRecoilValue(userAtom);

  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  const Wrapper = user ? AppNavigation : Fragment;

  return (
    <Wrapper>
      <Root key="loadingScreen">
        <Box width={400}>
          <LinearProgress />
        </Box>
      </Root>
    </Wrapper>
  );
};

export default LoadingScreen;
