import React, { Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import AuthGuard from './autenticacion/componentes/AuthGuard';
import NotAuthGuard from './autenticacion/componentes/NotAuthGuard';
import ProtectedRoute from './componentes/AppNavigation/ProtectedRoute/ProtectedRoute';
import LoadingScreen from './vistas/Loading/Loading';

const PaginaPrincipal = React.lazy(
  () => import('./vistas/PaginaPrincipal/PaginaPrincipal'),
);
const Login = React.lazy(() => import('./vistas/Login/Login'));
const Cliente = React.lazy(() => import('./vistas/Cliente/Cliente'));
const CrearCliente = React.lazy(() => import('./vistas/Cliente/CrearCliente'));
const EditarCliente = React.lazy(
  () => import('./vistas/Cliente/EditarCliente'),
);
const Empleado = React.lazy(() => import('./vistas/Empleado/Empleado'));
const CrearEmpleado = React.lazy(
  () => import('./vistas/Empleado/CrearEmpleado'),
);
const EditarEmpleado = React.lazy(
  () => import('./vistas/Empleado/EditarEmpleado'),
);
const Ruta = React.lazy(() => import('./vistas/Ruta/Ruta'));
const Compra = React.lazy(() => import('./vistas/Compra/Compra'));
const CrearCompra = React.lazy(
  () => import('./vistas/CrearCompra/CrearCompra'),
);
const CrearPedido = React.lazy(() => import('./vistas/Pedido/CrearPedido'));
const DetallesDePedido = React.lazy(
  () => import('./vistas/Pedido/DetallesDePedido'),
);
const ConceptoDeGasto = React.lazy(
  () => import('./vistas/ConceptoDeGasto/ConceptoDeGasto'),
);
const Credito = React.lazy(() => import('./vistas/Credito/Credito'));
const Devolucion = React.lazy(() => import('./vistas/Devolucion/Devolucion'));
const Flujo = React.lazy(() => import('./vistas/Flujo/Flujo'));
const Pedido = React.lazy(() => import('./vistas/Pedido/Pedido'));
const Preventa = React.lazy(() => import('./vistas/Preventa/Preventa'));
const Producto = React.lazy(() => import('./vistas/Producto/Producto'));
const EditarProducto = React.lazy(
  () => import('./vistas/Producto/EditarProducto'),
);
const CrearProducto = React.lazy(
  () => import('./vistas/Producto/CrearProducto'),
);
const Proveedor = React.lazy(() => import('./vistas/Proveedor/Proveedor'));
const CrearProveedor = React.lazy(
  () => import('./vistas/Proveedor/CrearProveedor'),
);
const EditarProveedor = React.lazy(
  () => import('./vistas/Proveedor/EditarProveedor'),
);
const Reparto = React.lazy(() => import('./vistas/Reparto/Reparto'));
const Reporte = React.lazy(() => import('./vistas/Reporte/Reporte'));
const VentaAlmacen = React.lazy(
  () => import('./vistas/VentaAlmacen/VentaAlmacen'),
);
const UnidadDeMedida = React.lazy(
  () => import('./vistas/UnidadDeMedida/UnidadDeMedida'),
);
const Usuarios = React.lazy(() => import('./vistas/Usuarios/Usuarios'));
const CrearUsuario = React.lazy(() => import('./vistas/Usuarios/CrearUsuario'));
const EditarUsuario = React.lazy(
  () => import('./vistas/Usuarios/EditarUsuario'),
);
const Permisos = React.lazy(() => import('./vistas/Permisos/Permisos'));
const EditorDePerfiles = React.lazy(
  () => import('./vistas/Perfiles/EditorDePerfiles'),
);
const Perfiles = React.lazy(() => import('./vistas/Perfiles/Perfiles'));
const Gasto = React.lazy(() => import('./vistas/Gasto/Gasto'));
const CrearGasto = React.lazy(() => import('./vistas/Gasto/CrearGasto'));
const EditarGasto = React.lazy(() => import('./vistas/Gasto/EditarGasto'));
const Error = React.lazy(() => import('./vistas/Error/Error'));

const Router: React.FC = () => {
  const routes: Array<RouteObject> = [
    {
      path: '/',
      element: <AuthGuard />,
      children: [
        {
          path: '/',
          element: <PaginaPrincipal />,
        },
        {
          path: '/cliente/crear',
          element: (
            <ProtectedRoute modulo='cliente' componente={CrearCliente} />
          ),
        },
        {
          path: '/cliente/editar/:idCliente',
          element: (
            <ProtectedRoute modulo='cliente' componente={EditarCliente} />
          ),
        },
        {
          path: '/cliente',
          element: <ProtectedRoute modulo='cliente' componente={Cliente} />,
        },
        {
          path: '/empleado',
          element: <ProtectedRoute modulo='empleados' componente={Empleado} />,
        },
        {
          path: '/empleado/crear',
          element: (
            <ProtectedRoute modulo='empleados' componente={CrearEmpleado} />
          ),
        },
        {
          path: '/empleado/editar/:idEmpleado',
          element: (
            <ProtectedRoute modulo='empleados' componente={EditarEmpleado} />
          ),
        },
        {
          path: '/proveedor/crear',
          element: (
            <ProtectedRoute modulo='proveedores' componente={CrearProveedor} />
          ),
        },
        {
          path: '/proveedor/editar/:idProveedor',
          element: (
            <ProtectedRoute modulo='proveedores' componente={EditarProveedor} />
          ),
        },
        {
          path: '/proveedor',
          element: (
            <ProtectedRoute modulo='proveedores' componente={Proveedor} />
          ),
        },
        {
          path: '/ruta',
          element: <ProtectedRoute modulo='ruta' componente={Ruta} />,
        },
        {
          path: '/compra/crear',
          element: <ProtectedRoute modulo='compras' componente={CrearCompra} />,
        },
        {
          path: '/pedido/crear',
          element: (
            <ProtectedRoute
              modulo='pedidos'
              // permiso={{ modulo: "pedidos", nombre: "crear" }}
              componente={CrearPedido}
            />
          ),
        },
        {
          path: '/pedido/crear/almacen',
          element: <ProtectedRoute modulo='pedidos' componente={CrearPedido} />,
        },
        {
          path: '/pedido/:pedidoId',
          element: (
            <ProtectedRoute modulo='pedidos' componente={DetallesDePedido} />
          ),
        },
        {
          path: '/compra',
          element: <ProtectedRoute modulo='compras' componente={Compra} />,
        },
        {
          path: '/conceptodegasto',
          element: (
            <ProtectedRoute
              modulo='concepto de gastos'
              componente={ConceptoDeGasto}
            />
          ),
        },
        {
          path: '/credito',
          element: <ProtectedRoute modulo='credito' componente={Credito} />,
        },
        {
          path: '/devolucion',
          element: (
            <ProtectedRoute modulo='devoluciones' componente={Devolucion} />
          ),
        },
        {
          path: '/flujo',
          element: <ProtectedRoute modulo='flujos' componente={Flujo} />,
        },
        {
          path: '/pedido',
          element: <ProtectedRoute modulo='pedidos' componente={Pedido} />,
        },
        {
          path: '/preventa',
          element: <ProtectedRoute modulo='preventa' componente={Preventa} />,
        },
        {
          path: '/producto/crear',
          element: (
            <ProtectedRoute
              modulo='productos'
              permiso='crear'
              componente={CrearProducto}
            />
          ),
        },
        {
          path: '/producto/editar/:idProducto',
          element: (
            <ProtectedRoute
              modulo='productos'
              permiso='editar'
              componente={EditarProducto}
            />
          ),
        },
        {
          path: '/producto',
          element: <ProtectedRoute modulo='productos' componente={Producto} />,
        },
        {
          path: '/reparto',
          element: <ProtectedRoute modulo='reparto' componente={Reparto} />,
        },
        {
          path: '/reporte',
          element: <ProtectedRoute modulo='reporte' componente={Reporte} />,
        },
        {
          path: '/ventaAlmacen',
          element: (
            <ProtectedRoute
              modulo='venta de almacen'
              componente={VentaAlmacen}
            />
          ),
        },
        {
          path: '/unidaddemedida',
          element: (
            <ProtectedRoute
              modulo='unidades de medida'
              componente={UnidadDeMedida}
            />
          ),
        },
        {
          path: '/usuarios',
          element: <ProtectedRoute modulo='usuarios' componente={Usuarios} />,
        },
        {
          path: '/usuario/crear',
          element: (
            <ProtectedRoute modulo='usuarios' componente={CrearUsuario} />
          ),
        },
        {
          path: '/usuario/editar/:idUsuario',
          element: (
            <ProtectedRoute modulo='usuarios' componente={EditarUsuario} />
          ),
        },
        {
          path: '/permisos',
          element: <ProtectedRoute modulo='permisos' componente={Permisos} />,
        },
        {
          path: '/perfiles',
          element: <ProtectedRoute modulo='perfiles' componente={Perfiles} />,
        },
        {
          path: '/perfiles/:idDePerfil',
          element: (
            <ProtectedRoute modulo='perfiles' componente={EditorDePerfiles} />
          ),
        },
        {
          path: '/gasto',
          element: <ProtectedRoute modulo='gastos' componente={Gasto} />,
        },
        {
          path: '/gasto/crear',
          element: <ProtectedRoute modulo='gastos' componente={CrearGasto} />,
        },
        {
          path: '/gasto/editar/:idGasto',
          element: <ProtectedRoute modulo='gastos' componente={EditarGasto} />,
        },
      ],
    },
    {
      path: '/',
      element: <NotAuthGuard />,
      children: [
        { path: '/login', element: <Login /> },
        {
          path: '/signup',
          element: <React.Fragment />,
        },
        {
          path: '/non-user',
          element: <Error />,
        },
        {
          path: '/404',
          element: <Error />,
        },
        {
          path: '*',
          element: <Error />,
        },
      ],
    },
  ];

  const routing = useRoutes(routes);

  return <Suspense fallback={<LoadingScreen />}>{routing}</Suspense>;
};

export default Router;
