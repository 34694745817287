import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import React from 'react';
import { ComponentType, Fragment } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { atom, useRecoilState } from 'recoil';
import { Permiso } from '../../controladores/PermisosControlador/usePermisosControlador';
import { Contacto } from '../../entidades/Contacto';
import { useUsuarioControlador } from './../../controladores/UserControlador/useUserControlador';
import { useNavigate } from 'react-router-dom';

export interface ContactoConGrants extends Contacto {
  profile: {
    permisos: Permiso[];
  };
  userName: string;
  firstName: string;
  lastName: string;
}

export interface AuthState {
  user?: ContactoConGrants | undefined;
}

export const userAtom = atom<Partial<ContactoConGrants> | undefined>({
  key: 'userState',
  default: undefined,
});

export const tokenAtom = atom<string | undefined>({
  key: 'tokenState',
  default: localStorage.getItem('token') ?? undefined,
});

const defaultOnRedirecting = (): JSX.Element => <Fragment />;

export const useGetMeEffect = (): boolean => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    logout,
    user: auth0User,
  } = useAuth0();

  const [token, setToken] = useRecoilState(tokenAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const [getMeCalled, setGetMeCalled] = React.useState(false);
  const navigate = useNavigate();

  const { buscarUsuarioPorNombreDeUsuario } = useUsuarioControlador();

  React.useEffect(() => {
    if (!token && !user) {
      getAccessTokenSilently().then((token) => {
        setToken(token);
        localStorage.setItem('token', token);
      });
    }
  }, [getAccessTokenSilently, setToken, token, user]);

  React.useEffect(() => {
    if (token && isAuthenticated && !user && auth0User?.email && !getMeCalled) {
      buscarUsuarioPorNombreDeUsuario(auth0User.email)
        .then((userData) => {
          if ((userData as unknown as { errorCode: string })?.errorCode) {
            logout();
            // navigate("/login");
            return;
          }
          setUser(userData);
        })
        .catch((error) => {
          console.log(error);
        });
      setGetMeCalled(true);
    }
  }, [
    auth0User?.email,
    buscarUsuarioPorNombreDeUsuario,
    getMeCalled,
    isAuthenticated,
    logout,
    navigate,
    setUser,
    token,
    user,
  ]);

  return isLoading || !user;
};

export const withCustomAuthenticationRequired = <
  P extends Record<string, unknown>,
>(
  Component: ComponentType<P>,
  options: WithAuthenticationRequiredOptions = {},
): React.FC<P> => {
  return function WithAuthenticationRequired(props: P): JSX.Element {
    const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
    const loading = useGetMeEffect();

    React.useEffect(() => {
      if (isLoading || isAuthenticated) {
        return;
      }
      loginWithRedirect();
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    const { onRedirecting = defaultOnRedirecting } = options;

    return isAuthenticated && !loading ? (
      <Component {...props} />
    ) : (
      onRedirecting()
    );
  };
};
