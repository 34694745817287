import { atom } from "recoil";

export enum AlertSeverity {
  WARNING = "warning",
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
}

export interface AlertMessage {
  message: string;
  severity: AlertSeverity;
  key?: string;
}

export const alertAtom = atom<AlertMessage[]>({
  key: "alertState",
  default: [],
});
