import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import { Auth0Provider } from "@auth0/auth0-react";

ReactDOM.render(
  <Auth0Provider
    domain="dev-td0iuckg.us.auth0.com"
    clientId="pcKW1vLFPaa5TCx1kJpT3kVVYg6PLqVf"
    redirectUri={window.location.origin}
    audience="https://dev-td0iuckg.us.auth0.com/api/v2/"
  >
    <RecoilRoot>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </RecoilRoot>
  </Auth0Provider>,
  document.getElementById("root")
);

reportWebVitals();
