import { ContactoConGrants } from "../atoms/AuthState";

const loadStoredData = async () => {
  const session = await sessionStorage.getItem("gqgUser");

  if (session) {
    const userSession = JSON.parse(session);
    return userSession;
  } else {
    return null;
  }
};

const logout = async () => {
  await sessionStorage.removeItem("gqgUser");
  window.location.reload();
};

const createSetAndStoreFunction = async (user: Partial<ContactoConGrants>) => {
  await sessionStorage.setItem("gqgUser", JSON.stringify(user));
};

export { loadStoredData, createSetAndStoreFunction, logout };
