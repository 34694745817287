import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CustomColors {
    colors: {
      silver: string;
      whiteSmoke: string;
      white: string;
      gainsboro: string;
      alizarin: string;
      porsche: string;
    };
  }
  interface Theme extends CustomColors {
    status: {
      error: string;
      success: string;
      warning: string;
    }
  }

  interface ThemeOptions extends CustomColors {
    status?: {
      error?: string;
      success?: string;
      warning?: string;
    };
  }
}

const theme = createTheme({
  status: {
    success: "#1eb700",
    warning: "#ffe605",
    error: "#b70030",
  },
  palette: {
    primary: {
      main: "#DE1C28",
    },
    secondary: {
      main: "#ff5c52",
    },
    error: {
      main: "#a40000",
    },
    text: {
      primary: "#000000",
      secondary: "#4c4c4c",
    },
  },
  typography: {
    fontFamily: ["Cambay"].join(","),
    subtitle1: {
      fontSize: "14px",
    },
  },
  colors: {
    silver: '#C4C4C4',
    whiteSmoke: '#F0F0F0',
    white: '#FFFFFF',
    gainsboro: '#e0e0e0',
    alizarin: '#de1c28',
    porsche: '#DA9D53'
  }
});

export default theme;
