import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const NotAuthGuard: React.FC = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const user = useRecoilValue(userAtom);
  const { isAuthenticated, isLoading } = useAuth0();

  React.useEffect(() => {
    if (isAuthenticated && !isLoading) {
      navigate("/");
      return;
    } else {
      navigate("/login");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading]);

  return <Outlet />;
};

export default NotAuthGuard;
