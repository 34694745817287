import { useState, useEffect } from "react";
import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosStatic,
  Method,
} from "axios";
import { SODASOFT_API_ENDPOINT } from "../constantes/apiUrl";
import { useRecoilValue } from "recoil";
import { tokenAtom } from "../autenticacion/atoms/AuthState";

interface UseFetchParams {
  initialUrl: string;
  initialParams?: Record<string, string | number | boolean>;
  skip?: boolean;
  initialBody?: Record<string, string | number | boolean>;
  method?: Method;
  axiosInstance?: AxiosStatic;
  baseURL?: string;
}

interface UseFetchResult {
  data: Record<string, string | number | boolean> | null;
  isLoading: boolean;
  hasError: boolean;
  errorMessage: string;
  updateUrl: (newValue: string) => void;
  updateParams: (newValue: Record<string, string | number | boolean>) => void;
  refetch: () => void;
  updateBody: (newValue: Record<string, string | number | boolean>) => void;
}

export const useFetch = (argParams: UseFetchParams): UseFetchResult => {
  const {
    initialUrl,
    initialParams = {},
    skip,
    initialBody,
    method = "get",
    baseURL,
  } = argParams;

  const token = useRecoilValue(tokenAtom);

  const headers: AxiosRequestHeaders = {
    "Content-type": "application/json; charset=UTF-8",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const axiosInstance = axios.create({
    baseURL: baseURL || SODASOFT_API_ENDPOINT,
    headers: headers,
  });

  const [url, updateUrl] = useState(initialUrl);
  const [params, updateParams] = useState(initialParams);
  const [body, updateBody] = useState(initialBody);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [refetchIndex, setRefetchIndex] = useState(0);
  const queryString = Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
  const refetch = (): void =>
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);
  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      if (skip) return;
      setIsLoading(true);
      try {
        const response = await axiosInstance({
          method,
          url: `${url}${queryString ? "?" : ""}${queryString}`,
          data: body,
        });
        const result = await response.data;
        setData(result);
      } catch (err) {
        const error: Error | AxiosError = err as Error | AxiosError;
        setHasError(true);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params, refetchIndex, body]);
  return {
    data,
    isLoading,
    hasError,
    errorMessage,
    updateUrl,
    updateParams,
    refetch,
    updateBody,
  };
};
