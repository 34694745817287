import { useAuth0 } from '@auth0/auth0-react';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../autenticacion/atoms/AuthState';
import { logout } from '../../autenticacion/utils/userDataStorage';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import theme from '../../tema/Tema';
import ListItemText from '@material-ui/core/ListItemText';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import { styled } from '@mui/system';
import tema from '../../tema/Tema';

const drawerWidth = 240;
const topBarHeight = 64;
const topBarMobileHeight = 56;

export const UserContainer = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '1rem',
}));

export const UserIcon = styled(TripOriginIcon)(() => ({
  color: tema.colors.alizarin,
  marginRight: '1rem',
}));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactNode;
}

export default function AppNavigation(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [user, setUser] = useRecoilState(userAtom);

  const { logout: auth0Logout } = useAuth0();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const drawer = (
    <div>
      <UserContainer>
        <UserIcon />
        <Typography variant='h5' noWrap>
          {`${user?.firstName} ${user?.lastName}`}
        </Typography>
      </UserContainer>
      <Divider />
      <List>
        {[
          'Producto',
          'Cliente',
          'Empleado',
          'Proveedor',
          'Preventa',
          'Reparto',
          'Pedido',
          'Compra',
          'Ruta',
        ].map((text, index) => (
          <ListItem
            button
            component={Link}
            to={`/${text.replaceAll(' ', '').toLowerCase()}`}
            key={text}
          >
            <ListItemIcon>
              <DoubleArrowIcon style={{ color: theme.colors.porsche }} />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {[
          'Flujo',
          'Credito',
          'Devolucion',
          'Venta Almacen',
          'Gasto',
          'Concepto De Gasto',
          'Unidad De Medida',
          'Usuarios',
          'Perfiles',
          'Permisos',
        ].map((text, index) => (
          <ListItem
            button
            component={Link}
            to={`/${text.replaceAll(' ', '').toLowerCase()}`}
            key={text}
          >
            <ListItemIcon>
              <DoubleArrowIcon style={{ color: theme.colors.porsche }} />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Reporte'].map((text, index) => (
          <ListItem
            button
            component={Link}
            to={`/${text.replaceAll(' ', '').toLowerCase()}`}
            key={text}
          >
            <ListItemIcon>
              <DoubleArrowIcon style={{ color: theme.colors.porsche }} />
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant='h6'
            noWrap
            component='div'
            style={{ marginRight: 'auto' }}
          >
            Grupo QG - {`${user?.userName || ''}`}
          </Typography>
          <IconButton
            aria-label='open drawer'
            edge='end'
            onClick={async () => {
              setUser(undefined);
              await logout();
              auth0Logout();
            }}
            sx={{ mr: 2, fontSize: 40 }}
          >
            <LogoutIcon
              fontSize='large'
              style={{ color: tema.colors.whiteSmoke }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: { sm: `${topBarHeight}px)`, xs: `${topBarMobileHeight}px` },
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
