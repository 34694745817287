import React, { ComponentClass, FC, ExoticComponent } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../../autenticacion/atoms/AuthState';
import { Permiso } from '../../../controladores/PermisosControlador/usePermisosControlador';

export interface ProtectedRouteProps {
  componente: ComponentClass | ExoticComponent;
  modulo: Permiso['modulo'];
  permiso?: Permiso['nombre'];
}

const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const userState = useRecoilValue(userAtom);
  const { profile } = userState || {};
  const { componente: Componente, modulo, permiso } = props;

  React.useEffect(() => {
    if (userState) {
      console.log(userState);
    }
  }, [userState]);

  const { permisos = [] } = profile || {};
  const getModulosAutorizados = () => {
    if (!permisos?.length) {
      return [];
    }
    return permisos.reduce((acumulador, permiso) => {
      return acumulador.includes(permiso.modulo)
        ? acumulador
        : [...acumulador, permiso.modulo];
    }, [] as string[]);
  };

  const modulosAutorizados = getModulosAutorizados();
  console.log('modulosAutorizados :>> ', modulosAutorizados);
  const autorizacionModular = modulosAutorizados.includes(modulo);
  const isAuthorized = permiso
    ? autorizacionModular &&
      !!permisos?.find(
        ({ nombre, modulo: moduloDePermiso }) =>
          nombre === permiso && modulo === moduloDePermiso,
      )
    : autorizacionModular;

  return isAuthorized ? <Componente /> : <Navigate to={{ pathname: '/' }} />;
};

export default ProtectedRoute;
