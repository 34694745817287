import { useSnackbar } from 'notistack';
import { ContactoConGrants } from "../../autenticacion/atoms/AuthState";
import { useAxios } from "../../hooks/useAxios";
import { useFetch } from "../../hooks/useFetch";
import { Perfil } from "../PerfilControlador/usePerfilControlador";
import { RequiredPick } from "./../../utils/requiredPick";

export enum StatusDeUsuario {
  PAGADO = "pagado",
  PENDIENTE = "pendiente",
  ABONOS = "abonos",
}
export interface Usuario {
  id: number;
  firstName: string;
  lastName: string;
  age: number;
  userName: string;
  profile: RequiredPick<Perfil, "id">;
}

export type ActualizarUsuarioPayload = RequiredPick<Usuario, "id">;

export const useUsuarioControlador = () => {
  /**
   * Este controlador se encarga de:
   *  [X]  leer todos los usuario
   *  [X]  crear usuario
   *  [X]  editar usuario
   *  [X]  eliminar usuario
   */
  const { enqueueSnackbar } = useSnackbar();

  const { data, isLoading, refetch } = useFetch({
    initialUrl: "/users",
  });

  const [postUsuario] = useAxios({
    urlInicial: "/users",
    method: "post",
    callback: async (_data) => {
      await refetch();
    },
  });
  const [deleteUsuario] = useAxios({
    urlInicial: "/users",
    method: "delete",
    callback: async (_data) => {
      await refetch();
    },
  });
  const [_buscarUsuario] = useAxios({
    urlInicial: "/users",
  });

  const buscarUsuario = async (id: number) => {
    const result = await _buscarUsuario({
      url: `/users/${id}`,
    });

    return result as unknown as Usuario;
  };

  const buscarUsuarioPorNombreDeUsuario = async (nombreDeUsuario: string) => {
    try {
      const result = await _buscarUsuario({
        url: `/users/byusername/${nombreDeUsuario}`,
      });
      return JSON.parse(
        result as unknown as string
      ) as unknown as ContactoConGrants;
    } catch (error) {
      console.log(error);
    }
  };

  const agregarUsuario = (usuario: Usuario, callback?: () => void) => {
    postUsuario({
      body: usuario as unknown as Record<string, unknown>,
      extraCallback: () => {
        enqueueSnackbar("Usuario creado con éxito", {
          preventDuplicate: true,
          variant: "success",
        });
        callback && callback();
      },
    });
  };

  const eliminarUsuario = (id: number) => {
    deleteUsuario({
      url: `/users/${id}`,
      extraCallback: () => {
        enqueueSnackbar("Usuario borrado con éxito", {
          preventDuplicate: true,
          variant: "error",
        });
      },
    });
  };

  const editarUsuario = (usuario: RequiredPick<Usuario, "id">, callback?: () => void) => {  
    postUsuario({
      body: usuario as unknown as Record<string, unknown>,
      extraCallback: () => {
        enqueueSnackbar("Usuario editado con éxito", {
          preventDuplicate: true,
          variant: "success",
        });
        callback && callback();
      },
    });
  };

  return {
    usuarios: data as unknown as Required<Usuario>[],
    usuarioCargando: isLoading,
    refetchUsuarios: refetch,
    agregarUsuario,
    eliminarUsuario,
    editarUsuario,
    buscarUsuario,
    buscarUsuarioPorNombreDeUsuario,
  };
};
